// @flow
import React from 'react';
import { get } from 'lodash';
import components from '../components';

type Props = {
  uid: string,
  slices: [{
    componentName: string,
    // In principle we can pass data for any kind of slice,
    // so we can't make many assumptions about the shape of their data.
    data: any
  }]
};

class NestedPageSection extends React.Component<Props> {
  render() {
    const slices = get(this.props, 'slices', []);
    const uid = get(this.props, 'uid');
    return (
      <section>
        <div className="section-anchor" id={uid} />
        {slices && slices.map(({ componentName, data }, j) => {
          const Component = components[componentName];
          return <Component key={j} {...data} />
        })}
      </section>
    );
  }
}

export default NestedPageSection;
