// Based on
// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

type Props = {
  activeClassName?: string,
  className?: string,
  children?: React.Node,
  partiallyActive: boolean,
  to: string | { link_type: string },
};

// eslint-disable-next-line no-undef
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
class Link extends React.Component<Props> {
  render() {
    const {
      children,
      activeClassName,
      partiallyActive,
      className,
      to,
      ...other
    } = this.props;

    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash.
    // Also, exclude links containing slashes to not break internal anchor links
    // across pages.
    const internal = /^\/(?!\/)/.test(to) && !(to.includes('#'));

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      return (
        <GatsbyLink
          to={to}
          activeClassName={activeClassName}
          className={className}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }
    return (
      <a href={to} {...other} className={className}>
        {children}
      </a>
    );
  }
}

export default Link;
