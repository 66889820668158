import React from 'react';
import { Helmet } from 'react-helmet';
import FooterContainer from './footer';
import MenuContainer from './menu';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

type Props = {
  children: React.Node,
};

const PageLayout = (props: Props) => {

  const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `);

  const {children} = props;

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>{get(data, 'site.siteMetadata.title')}</title>
        <meta
          name="description"
          content={get(data, 'site.siteMetadata.description')}
        />
      </Helmet>
      <MenuContainer />
      <main>
        {children}
      </main>
      <FooterContainer />
    </>
  );
}

export default PageLayout;
