// @flow
import React from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';
import LinkedinIcon from '../assets/images/linkedIn.svg';
import TwitterIconImg from '../assets/images/twitter.svg';
import InstagramIconImg from '../assets/images/instagram.svg';
import MediumIconImg from '../assets/images/medium.svg';
import YoutubeIconImg from '../assets/images/youtubeIcon.svg';
import PictogramFile from '../assets/pictogram/gray.png';
import type { RichText as RichTextType } from '../utils/types';
import { isRichText, sanitizeRichText } from '../utils/types';
import { RichText } from 'prismic-reactjs';
import { Info } from '../elements';
import Link from '../utils/link';

type Props = {
  address: RichTextType,
  contactUs: RichTextType,
  contactUsLink: string,
  socialIcons: [
    {
      social: 'instagram' | 'medium' | 'linkedin' | 'twitter' | 'medium',
      link: string,
    }
  ],
};

type State = {};

const FooterBigScreen = styled.footer.attrs({
  className: 'px-6 hidden overflow-hidden lg:block my-20',
})``;

const RowMaxWidth = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto',
})``;

const Row = styled.div.attrs({
  className: 'flex flex-row pt-20 mx-10 lg:-mx-10',
})``;

const Line = styled.div.attrs({
  className: 'footer-border',
})`

`;

const Column = styled.div.attrs({
  className: 'flex-1 mx-10',
})``;

const LastColumn = styled.div.attrs({
  className: 'flex-1 mx-10 text-right',
})``;

const AboutFooter = styled.div.attrs({
  className:
    'flex flex-row items-center footer-text mt-8',
})``;

const Title = styled(Info).attrs({
  className: 'footer-title py-1',
})`
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const Pictogram = styled.img.attrs({
  src: PictogramFile,
  className: 'h-8 mr-3',
})``;

const ContactBody = styled.div.attrs({
  className: 'footer-text mt-6',
})``;

const AddressBody = styled.address.attrs({
  className: 'footer-text mt-4',
})``;

const SocialMediaIcon = styled.img.attrs({
  className: 'h-8',
})``;

const LinkedInIcon = styled(SocialMediaIcon).attrs({
  src: LinkedinIcon,
})``;

const TwitterIcon = styled(SocialMediaIcon).attrs({
  src: TwitterIconImg,
})``;

const MediumIcon = styled(SocialMediaIcon).attrs({
  src: MediumIconImg,
})``;

const InstagramIcon = styled(SocialMediaIcon).attrs({
  src: InstagramIconImg,
})``;

const YoutubeIcon = styled(SocialMediaIcon).attrs({
  src: YoutubeIconImg,
})`
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
`;

const SocialIcons = styled.div.attrs({
  className: 'flex flex-row justify-end mt-8',
})``;

const ArrowUpIcon = styled(FaArrowUp).attrs({
  className: 'up-arrow ml-3 float-right',
  size: '1em',
})``;

const FooterSmallScreen = styled.footer.attrs({
  className: 'block mb-5 border-t-2 border-gray-300 lg:hidden',
})``;

const PictogramSmallScreen = styled.p.attrs({
  className:
    'flex flex-row justify-center footer-text mt-6',
})``;

const LinksSmallScreen = styled.div.attrs({
  className: 'flex flex-row mx-8 mt-8',
})``;

const LinkSmallScreen = styled(Link).attrs({
  className:
    'small-footer-link',
})``;

const LinkSocial = styled(Link).attrs({
  className: 'ml-3 rounded-full blurred-outline',
  title: {},
})``;

const LinkToTopRow = styled.div.attrs({
  className: 'flex flex-row mr-16 justify-end',
})``;

const LinkToTop = styled.a.attrs({
  className:
    'flex items-center footer-text font-heading cursor-pointer py-1',
})``;

const getLinkTitleFromSocialMediumString = (str) => {
  const stringToTitleMap = {
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    medium: 'Medium',
    twitter: 'Twitter',
    youtube: 'YouTube',
  };
  return stringToTitleMap[str] || '';
};

class Footer extends React.Component<Props, State> {
  render() {
    const {
      address,
      contactUs,
      contactUsLink,
      socialIcons,
    } = this.props;
    return (
      <>
        <FooterBigScreen>
          <RowMaxWidth>
            <Line />
            <Row>
              <Column>
                <AboutFooter>
                  <Pictogram />
                </AboutFooter>
              </Column>
              <Column>
                <Title>Contact Us</Title>
                <ContactBody>
                  <Link to={contactUsLink}>{contactUs}</Link>
                </ContactBody>
              </Column>
              <Column>
              <Title>Address</Title>
              {isRichText(address) && (
                <AddressBody>
                  <Info>{RichText.render(sanitizeRichText(address))}</Info>
                </AddressBody>
              )}
              </Column>
              <LastColumn>
                <SocialIcons>
                  {socialIcons && socialIcons.length && socialIcons.map(({ social, link }) => (
                    <LinkSocial
                      key={social}
                      title={getLinkTitleFromSocialMediumString(social)}
                      to={link}
                    >
                      {
                        {
                          linkedin: <LinkedInIcon />,
                          twitter: <TwitterIcon />,
                          instagram: <InstagramIcon />,
                          youtube: <YoutubeIcon />,
                          medium: <MediumIcon />,
                        }[social]
                      }
                    </LinkSocial>
                  ))}
                </SocialIcons>
              </LastColumn>
            </Row>
            <LinkToTopRow>
              <LinkToTop
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Back to Top
                <ArrowUpIcon />
              </LinkToTop>
            </LinkToTopRow>
          </RowMaxWidth>
        </FooterBigScreen>
        <FooterSmallScreen>
          <PictogramSmallScreen>
            <Pictogram />
          </PictogramSmallScreen>
          <LinksSmallScreen>
            <Info>Contact Us</Info>
            <LinkSmallScreen>
              <Link to={contactUsLink}>{contactUs}</Link>
            </LinkSmallScreen>
          </LinksSmallScreen>
        </FooterSmallScreen>
      </>
    );
  }
}

export default Footer;
