
    import React from 'react';
    import { NavigationMenu } from 'nbt-uikit';
    import { useStaticQuery, graphql } from 'gatsby';

    type Props = {
      onToggleMenu: () => void,
      showMenu: boolean,
    };

    const MenuContainer = (props: Props) => {
      const data = useStaticQuery(graphql`
    query {
      menuJson {
         bigScreenLogoUrl smallScreenLogoUrl menuItems {  text link } menuItemsLayout
      }
    }`);

      const menuProps = data.menuJson;

      if (!menuProps) return null;

      return (
        <NavigationMenu
          {...menuProps}
          onToggleMenu={props.onToggleMenu}
          showMenu={props.showMenu}
        />
      );
    };
    export default MenuContainer;
  