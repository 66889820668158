
    import React from 'react';
    import Footer from '../components/footer';
    import { useStaticQuery, graphql } from 'gatsby';

    const FooterContainer = () => {
      const data = useStaticQuery(graphql`
    query {
      footerJson {
         footer {  address {  type text spans {  start end type data {  link_type url } } } contactUs contactUsLink socialIcons {  social link } }
      }
    }`);

      const { footer } = data.footerJson;

      if (!footer) return null;

      return (
        <Footer
          {...footer}
        />
      );
    };
    export default FooterContainer;
  